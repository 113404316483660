import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import WorkflowExample from './components/sections/WorkflowExample';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

const App = () => {
  return (
    <BrowserRouter>
      <div className="min-h-screen">
        <Navbar /> {/* Single Navbar instance here */}
        <Routes>
          <Route path="/" element={<MainLayout />} />
          <Route path="/workflow" element={<WorkflowExample />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;