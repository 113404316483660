import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/button';

const Navbar = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const navbarHeight = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  return (
    <nav className={`w-full fixed top-0 z-50 transition-all duration-300 ${
      scrolled ? 'bg-gradient-to-r from-[#000000]/70 to-[#34011f]/70 backdrop-blur-md shadow-sm' : 'bg-transparent'
    }`}>
      <div className="max-w-screen-2xl mx-auto px-6 flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            <img
              src="/Logo.png"
              alt="Bedrock AI"
              className="h-24 w-auto"
            />
          </Link>
        </div>
        
        <div className="flex items-center space-x-8">
          {isHomePage ? (
            <>
              <button 
                onClick={() => scrollToSection('benefits')}
                className="text-white/80 hover:text-[#e229c4] transition-colors text-lg cursor-pointer"
              >
                Benefits
              </button>
              <button 
                onClick={() => scrollToSection('features')}
                className="text-white/80 hover:text-[#e229c4] transition-colors text-lg cursor-pointer"
              >
                Features
              </button>
            </>
          ) : (
            <Link 
              to="/"
              className="text-white/80 hover:text-[#e229c4] transition-colors text-lg"
            >
              Home
            </Link>
          )}
          
          <Link 
            to="/workflow"
            className="text-white/80 hover:text-[#e229c4] transition-colors text-lg"
          >
            See How It Works
          </Link>
          
          <Button 
            variant="primary"
            onClick={() => window.location.href = 'mailto:peter@bedrock-compliance.com'}
            className="bg-[#e229c4] hover:bg-[#a9088e] text-white shadow-soft hover:shadow-card transition-all"
          >
            Schedule Demo
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;