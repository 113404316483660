import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { Button } from '../ui/button';
import { WORKFLOW_STAGES } from '../../data/workflowStages';
import {
  Brain,
  Database,
  Lightbulb,
  CheckCircle2,
  Activity,
  Play,
  Pause,
  RotateCcw
} from 'lucide-react';

const BrainCard = ({ title, icon: Icon, color, action, result }) => {
  const colorMap = {
    purple: {
      text: 'text-primary-dark',
      bg: 'bg-primary/10',
      border: 'border-primary/20'
    },
    blue: {
      text: 'text-blue-600',
      bg: 'bg-blue-50',
      border: 'border-blue-200'
    },
    green: {
      text: 'text-green-600',
      bg: 'bg-green-50',
      border: 'border-green-200'
    }
  };

  const colors = colorMap[color];

  return (
    <Card>
      <CardHeader>
        <CardTitle className={`text-lg flex items-center gap-2 ${colors.text}`}>
          <Icon className="h-5 w-5" />
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <Alert className={`${colors.bg} ${colors.border}`}>
          <Activity className="h-4 w-4" />
          <AlertDescription>{action}</AlertDescription>
        </Alert>
        {result && (
          <Alert variant="outline">
            <CheckCircle2 className="h-4 w-4" />
            <AlertDescription>{result}</AlertDescription>
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

const WorkflowExample = () => {
  const [currentStage, setCurrentStage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setCurrentStage(stage => {
          if (stage < WORKFLOW_STAGES.length - 1) {
            return stage + 1;
          } else {
            setIsPlaying(false);
            return stage;
          }
        });
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isPlaying]);

  useEffect(() => {
    setProgress((currentStage / (WORKFLOW_STAGES.length - 1)) * 100);
  }, [currentStage]);

  const handleReset = () => {
    setCurrentStage(0);
    setIsPlaying(false);
    setProgress(0);
  };

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const stage = WORKFLOW_STAGES[currentStage];

  return (
    <section className="py-16 bg-background">
      <div className="max-w-7xl mx-auto px-4 space-y-8">
        {/* Header */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-foreground mb-4">
            See Bedrock AI in Action
          </h2>
          <p className="text-muted-foreground mb-8">
            Watch the three brains work together to validate a 2000L bioreactor
          </p>
        </div>

        {/* Controls */}
        <Card>
          <CardContent className="flex items-center justify-between p-4">
            <div className="flex items-center gap-4">
              <Button
                onClick={handlePlayPause}
                variant="outline"
                size="sm"
              >
                {isPlaying ? (
                  <Pause className="h-4 w-4 mr-2" />
                ) : (
                  <Play className="h-4 w-4 mr-2" />
                )}
                {isPlaying ? "Pause" : "Start"} Demo
              </Button>
              <Button
                onClick={handleReset}
                variant="outline"
                size="sm"
              >
                <RotateCcw className="h-4 w-4 mr-2" />
                Reset
              </Button>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-sm text-muted-foreground">Stage {currentStage + 1}/5:</span>
              <span className="font-semibold text-foreground">{stage.title}</span>
            </div>
            <div className="w-1/3 bg-muted rounded-full h-2.5">
              <div
                className="bg-primary h-2.5 rounded-full transition-all duration-500"
                style={{ width: `${progress}%` }}
              />
            </div>
          </CardContent>
        </Card>

        {/* Three Brains */}
        <div className="grid grid-cols-3 gap-6">
          <BrainCard
            title="Smart Brain"
            icon={Brain}
            color="purple"
            action={stage.smartBrain.action}
            result={stage.smartBrain.result}
          />
          <BrainCard
            title="Memory Brain"
            icon={Database}
            color="blue"
            action={stage.memoryBrain.action}
            result={stage.memoryBrain.result}
          />
          <BrainCard
            title="Learning Brain"
            icon={Lightbulb}
            color="green"
            action={stage.learningBrain.action}
            result={stage.learningBrain.result}
          />
        </div>

        {/* Workflow Timeline */}
        <Card>
          <CardContent className="p-4">
            <div className="flex justify-between">
              {WORKFLOW_STAGES.map((stageItem, index) => (
                <div
                  key={stageItem.id}
                  className={`flex flex-col items-center ${
                    index <= currentStage ? 'text-primary' : 'text-muted-foreground'
                  }`}
                >
                  <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                    index <= currentStage ? 'bg-primary/10' : 'bg-muted'
                  }`}>
                    {index <= currentStage ? (
                      <CheckCircle2 className="h-5 w-5" />
                    ) : (
                      <div className="w-3 h-3 rounded-full bg-muted-foreground/30" />
                    )}
                  </div>
                  <span className="text-sm mt-2">{stageItem.title}</span>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </section>
  );
};

export default WorkflowExample;