// src/components/sections/ThreeBrains.jsx
import React from 'react';
import { Brain, Database, Lightbulb } from 'lucide-react';

const BrainFeature = ({ icon: Icon, title, subtitle, descriptions, className = '' }) => {
  return (
    <div className={`bg-[#1a000f] p-8 rounded-2xl border border-[#e229c4]/20 
                    hover:border-[#e229c4]/40 transition-all duration-300 
                    group flex flex-col items-center text-center ${className}`}>
      <div className="h-16 w-16 bg-[#34011f] rounded-xl flex items-center 
                    justify-center mb-6 group-hover:bg-[#e229c4]/20 
                    transition-colors duration-300">
        <Icon className="w-8 h-8 text-[#e229c4]" />
      </div>
      <h4 className="text-xl font-bold text-[#e229c4] mb-2">{title}</h4>
      <p className="text-white/80 italic mb-6">{subtitle}</p>
      <ul className="space-y-3 w-full">
        {descriptions.map((desc, index) => (
          <li key={index} className="text-white/70">
            {desc}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ThreeBrains = () => {
  const features = [
    {
      icon: Brain,
      title: "Smart Brain",
      subtitle: "\"Like a validated Autopilot\"",
      descriptions: [
        "Validated AI decision engine",
        "Generates protocols and risk assessments",
        "Always follows regulations",
        "Never deviates from validated procedures"
      ]
    },
    {
      icon: Database,
      title: "Memory Brain",
      subtitle: "\"Like a living organisational archive\"",
      descriptions: [
        "Organisation's collective validation intelligence",
        "Connects equipment, processes, and regulations",
        "Preserves organisational knowledge",
        "Gets smarter with every validation"
      ]
    },
    {
      icon: Lightbulb,
      title: "Learning Brain",
      subtitle: "\"Like a continuous improvement expert\"",
      descriptions: [
        "Observes validation patterns",
        "Identifies improvement opportunities",
        "Studies successful approaches",
        "Suggests future enhancements"
      ]
    }
  ];

  return (
    <section id="features" className="w-full bg-gradient-to-r from-[#000000] to-[#34011f]">
      <div className="max-w-screen-2xl mx-auto px-6 py-16">
        <h3 className="text-4xl font-bold text-center text-[#e229c4] mb-4">
          The Three Brains of Validation Intelligence
        </h3>
        <p className="text-center text-white/80 mb-12 max-w-2xl mx-auto">
          Our three brain architecture transforms static validation into intelligent, learning systems
        </p>
        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <BrainFeature key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ThreeBrains;