// src/components/layout/Footer.jsx
const Footer = () => {
  return (
    <footer className="bg-[#e229c4] py-8">
      <div className="max-w-screen-2xl mx-auto px-6">
        <div className="flex flex-col items-center space-y-4">
          <p className="text-white/90 text-sm">
            © {new Date().getFullYear()} Bedrock AI. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;