// src/components/sections/TransformationPath.jsx
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FileText, Database, Brain } from 'lucide-react';

const TransformationPath = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        setIsVisible(entries[0].isIntersecting);
      },
      { threshold: 0.2 }
    );

    const element = document.getElementById('transformation-section');
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  const Stage = ({ title, description, era, icon: Icon, index }) => {
    const containerVariants = {
      hidden: { opacity: 0, y: 20 },
      visible: { 
        opacity: 1, 
        y: 0,
        transition: { 
          duration: 0.5,
          delay: 0.2 * index 
        }
      }
    };

    const circleVariants = {
      hidden: { scale: 0 },
      visible: {
        scale: 1,
        transition: {
          type: "spring",
          stiffness: 260,
          damping: 20,
          delay: 0.3 * index
        }
      }
    };

    return (
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        className="flex flex-col items-center relative"
      >
        <motion.div
          variants={circleVariants}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          className="w-32 h-32 rounded-full bg-white border-2 border-[#e229c4] 
                    flex items-center justify-center relative z-10"
        >
          <div className="w-24 h-24 rounded-full bg-[#e229c4]/10 flex items-center 
                        justify-center group-hover:bg-[#e229c4]/20 transition-colors">
            <Icon className="w-12 h-12 text-[#e229c4]" />
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={isVisible ? { opacity: 1 } : { opacity: 0 }}
          transition={{ delay: 0.4 * index }}
          className="text-center mt-6 space-y-2"
        >
          <span className="text-[#e229c4] text-sm">{era}</span>
          <h3 className="text-2xl font-bold text-gray-900">{title}</h3>
          <p className="text-gray-600 max-w-sm">{description}</p>
        </motion.div>
      </motion.div>
    );
  };

  const stages = [
    {
      title: "Paper Based",
      era: "1990s - 2010s",
      description: "Manual documentation, physical storage, and time-intensive verification processes requiring extensive human oversight",
      icon: FileText
    },
    {
      title: "Digital Validation",
      era: "2010s - 2023",
      description: "Cloud adoption brought validation into the digital age, replacing paper with significant efficiency gains - yet at its core, it's still just a database with a modern interface",
      icon: Database
    },
    {
      title: "AI Native Platform",
      era: "2024 & Beyond",
      description: "AI native brings validation into the intelligence era, replacing manual decisions with automated learning - revolutionising the industry through predictive analytics and real-time optimisation",
      icon: Brain
    }
  ];

  return (
    <section id="transformation-section" className="bg-white py-24">
      <div className="max-w-screen-2xl mx-auto px-6">
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h2 className="text-5xl font-bold text-[#e229c4] mb-6">
            The Evolution of Validation
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Transforming from documentation to intelligence
          </p>
        </motion.div>

        <div className="relative">
          {/* Animated connecting line */}
          <div className="absolute top-16 left-0 w-full flex items-center justify-center">
            <motion.div
              className="h-0.5 bg-[#e229c4] w-full max-w-4xl mx-auto"
              initial={{ scaleX: 0 }}
              animate={isVisible ? { scaleX: 1 } : { scaleX: 0 }}
              transition={{ duration: 1.5, delay: 0.5 }}
            />
            {/* Connection dots */}
            {[0, 1].map((index) => (
              <motion.div
                key={index}
                className="absolute w-3 h-3 bg-[#e229c4] rounded-full"
                style={{ left: `${33 + (index * 33)}%` }}
                initial={{ scale: 0 }}
                animate={isVisible ? { scale: 1 } : { scale: 0 }}
                transition={{ delay: 1 + (index * 0.2) }}
              />
            ))}
          </div>

          {/* Stages */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 relative z-10">
            {stages.map((stage, index) => (
              <Stage key={index} {...stage} index={index} />
            ))}
          </div>

          {/* Bottom labels */}
          <div className="grid grid-cols-3 mt-8">
            <motion.div
              initial={{ opacity: 0 }}
              animate={isVisible ? { opacity: 1 } : { opacity: 0 }}
              transition={{ delay: 1.5 }}
              className="text-center"
            >
              <span className="text-gray-500">Documentation</span>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={isVisible ? { opacity: 1 } : { opacity: 0 }}
              transition={{ delay: 1.7 }}
              className="text-center"
            >
              <span className="text-gray-500">Digitisation</span>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={isVisible ? { opacity: 1 } : { opacity: 0 }}
              transition={{ delay: 1.9 }}
              className="text-center"
            >
              <span className="text-gray-500">Intelligence</span>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TransformationPath;