// src/components/sections/CTA.jsx
import React from 'react';
import { Button } from '../ui/button';
import { 
  Users, 
  UserMinus, 
  TimerOff, 
  Sunset,
  Brain,
  Clock,
  Lightbulb,
  TrendingUp,
  Archive,
  Database,
  Trophy,
  Rocket
} from 'lucide-react';

const InfoCard = ({ icon: Icon, title, subtitle }) => (
  <div className="bg-[#1a000f] hover:bg-[#34011f] p-8 rounded-2xl 
                  border border-[#e229c4]/20 hover:border-[#e229c4]/40 
                  transition-all duration-300 flex flex-col items-center 
                  text-center group cursor-pointer">
    <div className="h-12 w-12 bg-[#34011f] group-hover:bg-[#e229c4]/20 
                    rounded-xl flex items-center justify-center mb-4
                    transition-colors duration-300">
      <Icon className="w-6 h-6 text-[#e229c4] group-hover:text-[#e229c4]" />
    </div>
    <h5 className="font-bold text-lg mb-2 text-[#e229c4]">{title}</h5>
    {subtitle && <p className="text-white/70 group-hover:text-white/90">{subtitle}</p>}
  </div>
);

const CTA = () => {
  const challengeItems = [
    {
      icon: UserMinus,
      title: "Contract Staff Departures",
      subtitle: "Knowledge walks out the door with every contractor"
    },
    {
      icon: Users,
      title: "Employee Turnover",
      subtitle: "25% of pharma quality professionals changed jobs in 2023"
    },
    {
      icon: TimerOff,
      title: "Project Transitions",
      subtitle: "Critical insights lost as teams move to new projects"
    },
    {
      icon: Sunset,
      title: "Retirement Wave",
      subtitle: "Senior validation experts retiring in next 5 years"
    }
  ];

  const competitionItems = [
    {
      icon: Brain,
      title: "Building Intelligence",
      subtitle: "Creating organisational validation intelligence"
    },
    {
      icon: Clock,
      title: "70% Faster",
      subtitle: "Reducing validation time dramatically"
    },
    {
      icon: Lightbulb,
      title: "Capturing Insights",
      subtitle: "Leveraging every piece of knowledge"
    },
    {
      icon: TrendingUp,
      title: "Gaining Advantage",
      subtitle: "Creating insurmountable knowledge advantages"
    }
  ];

  const actionItems = [
    {
      icon: Archive, // You can import this from lucide-react
      title: "Begin capturing knowledge",
      subtitle: "before more is lost"
    },
    {
      icon: Database, // Already imported
      title: "Start building validation intelligence",
      subtitle: "power your digital transformation"
    },
    {
      icon: Trophy, // You can import this from lucide-react
      title: "Join the leaders",
      subtitle: "in validation innovation"
    },
    {
      icon: Rocket, // You can import this from lucide-react
      title: "Transform risk",
      subtitle: "into competitive advantage"
    }
  ];

  return (
    <section className="bg-white py-24">
      <div className="max-w-screen-2xl mx-auto px-6">
        {/* Title section remains the same */}
        <div className="text-center mb-16">
          <h2 className="text-[#e229c4] text-5xl font-bold mb-6">
            Don't Risk Being Late
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Protect your investment in your digital transformation journey
          </p>
        </div>

        {/* Knowledge Erosion section remains the same */}
        <div className="mb-20">
          <h3 className="text-2xl font-bold mb-8 text-gray-800 text-center">
            Knowledge Erosion is Happening Now
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto mb-16">
            {challengeItems.map((item, index) => (
              <InfoCard key={index} {...item} />
            ))}
          </div>
        </div>

        {/* Competition section remains the same */}
        <div className="mb-20">
          <h3 className="text-2xl font-bold mb-8 text-gray-800 text-center">
            Meanwhile, Your Competition is...
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
            {competitionItems.map((item, index) => (
              <InfoCard key={index} {...item} />
            ))}
          </div>
        </div>

        {/* New Time to Act section */}
        <div className="mb-20">
  <h3 className="text-2xl font-bold mb-8 text-gray-800 text-center">
    The Time to Act is Now
  </h3>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
    {actionItems.map((item, index) => (
      <InfoCard key={index} {...item} />
    ))}
  </div>
</div>

        {/* CTA Button */}
        <div className="text-center">
          <Button 
            variant="primary"
            className="text-lg px-12 py-4 bg-[#e229c4] hover:bg-[#a9088e] 
                       transition-colors duration-300 shadow-lg hover:shadow-xl"
            onClick={() => window.location.href = 'mailto:peter@bedrock-compliance.com'}
          >
            Schedule a Demo Today
          </Button>
        </div>
      </div>
    </section>
  );
};

export default CTA;