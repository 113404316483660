export const WORKFLOW_STAGES = [
    {
      id: 1,
      title: "Initiation",
      smartBrain: {
        action: "Analyzing equipment specifications",
        result: "Identified BR-2000L configuration requirements"
      },
      memoryBrain: {
        action: "Searching knowledge graph",
        result: "Found 3 similar bioreactor validations"
      },
      learningBrain: {
        action: "Analyzing historical patterns",
        result: "Optimal parameter ranges detected"
      }
    },
    {
      id: 2,
      title: "Risk Assessment",
      smartBrain: {
        action: "Generating risk assessment",
        result: "Critical control points identified: Temperature, pH, DO"
      },
      memoryBrain: {
        action: "Retrieving past deviations",
        result: "Common issue: temperature probe placement"
      },
      learningBrain: {
        action: "Predicting potential risks",
        result: "Suggesting additional monitoring points"
      }
    },
    {
      id: 3,
      title: "Protocol Generation",
      smartBrain: {
        action: "Creating test protocols",
        result: "56 IQ/OQ test cases generated"
      },
      memoryBrain: {
        action: "Applying best practices",
        result: "Including proven test sequences"
      },
      learningBrain: {
        action: "Optimizing procedures",
        result: "Enhanced CIP validation steps added"
      }
    },
    {
      id: 4,
      title: "Execution",
      smartBrain: {
        action: "Monitoring test execution",
        result: "Real-time compliance verification"
      },
      memoryBrain: {
        action: "Providing context",
        result: "Similar deviation pattern detected"
      },
      learningBrain: {
        action: "Adapting test sequence",
        result: "Modified pH probe calibration timing"
      }
    },
    {
      id: 5,
      title: "Analysis",
      smartBrain: {
        action: "Validating results",
        result: "All critical parameters within range"
      },
      memoryBrain: {
        action: "Updating knowledge base",
        result: "New successful configuration documented"
      },
      learningBrain: {
        action: "Generating insights",
        result: "70% faster than previous validation"
      }
    }
  ];
  
  export default WORKFLOW_STAGES;