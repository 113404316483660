// src/components/sections/Benefits.jsx
import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';

const StatBox = ({ metric, label }) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const currentElement = elementRef.current;
    
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  return (
    <div 
      className="bg-[#a9088e] hover:bg-[#e229c4] transition-colors duration-300 
                 rounded-2xl p-8 text-center"
    >
      <div className="text-5xl font-bold text-white mb-4" ref={elementRef}>
        {isVisible && (
          <CountUp
            end={parseInt(metric)}
            duration={2.5}
            suffix="%"
            enableScrollSpy
            scrollSpyDelay={200}
            separator=","
            decimals={0}
            decimal="."
          >
            {({ countUpRef }) => (
              <span ref={countUpRef} />
            )}
          </CountUp>
        )}
      </div>
      <div className="text-white text-xl">{label}</div>
    </div>
  );
};

const Benefits = () => {
  const stats = [
    { metric: "70", label: "Faster Validation Execution" },
    { metric: "65", label: "Reduction in Documentation" },
    { metric: "50", label: "Decrease in Deviations" },
    { metric: "100", label: "Knowledge Preservation" },
  ];

  return (
    <section id="benefits" className="py-24 bg-gradient-to-r from-[#000000] to-[#34011f]">
      <div className="max-w-screen-2xl mx-auto px-6">
        <h3 className="text-5xl font-bold text-center text-white mb-16">
          Business Impact
        </h3>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <StatBox key={index} {...stat} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;