// src/components/layout/MainLayout.jsx
import React from 'react';
import Hero from '../sections/Hero';
import ThreeBrains from '../sections/ThreeBrains';
import TransformationPath from '../sections/TransformationPath';
import Benefits from '../sections/Benefits';
import CTA from '../sections/CTA';

const MainLayout = () => {
  return (
    <main>
      <Hero />
      <TransformationPath />
      <ThreeBrains />
      <Benefits />
      <CTA />
    </main>
  );
};

export default MainLayout;