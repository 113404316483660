import React from 'react';

const Hero = () => {
  return (
    <header className="w-full bg-gradient-to-r from-[#000000] to-[#34011f]">
      <div className="max-w-screen-2xl mx-auto px-6 py-40">
        <div className="max-w-5xl mx-auto text-center space-y-16"> {/* Increased from max-w-4xl to max-w-5xl */}
          <div className="space-y-8">
            <h2 className="text-7xl font-bold text-[#e229c4]">
              The Future of Validation
            </h2>
            <p className="text-2xl text-white/80">
              A digital validation platform built from the ground up to be AI native
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-24"> {/* Reduced gap from 12 to 8 to accommodate wider cards */}
            <div className="bg-[#1a000f] p-12 rounded-2xl border border-[#e229c4]/20 hover:border-[#e229c4]/40 
                          transition-all duration-300 group space-y-6 min-w-[280px]"> {/* Added min-width */}
              <div className="h-16 w-16 bg-[#34011f] rounded-xl flex items-center justify-center mx-auto
                            group-hover:bg-[#e229c4]/20 transition-colors duration-300">
                <svg className="w-8 h-8 text-[#e229c4]" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
                </svg>
              </div>
              <h3 className="text-2xl font-bold text-[#e229c4]">AI Native Platform</h3>
              <p className="text-lg text-white/70">A digital validation platform built from the ground up to be AI native</p>
            </div>

            <div className="bg-[#1a000f] p-12 rounded-2xl border border-[#e229c4]/20 hover:border-[#e229c4]/40 
                          transition-all duration-300 group space-y-6 min-w-[280px]">
              <div className="h-16 w-16 bg-[#34011f] rounded-xl flex items-center justify-center mx-auto
                            group-hover:bg-[#e229c4]/20 transition-colors duration-300">
                <svg className="w-8 h-8 text-[#e229c4]" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <h3 className="text-2xl font-bold text-[#e229c4]">Transform Validation</h3>
              <p className="text-lg text-white/70">Transforms validation from documentation to intelligence</p>
            </div>

            <div className="bg-[#1a000f] p-12 rounded-2xl border border-[#e229c4]/20 hover:border-[#e229c4]/40 
                          transition-all duration-300 group space-y-6 min-w-[280px]">
              <div className="h-16 w-16 bg-[#34011f] rounded-xl flex items-center justify-center mx-auto
                            group-hover:bg-[#e229c4]/20 transition-colors duration-300">
                <svg className="w-8 h-8 text-[#e229c4]" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
              </div>
              <h3 className="text-2xl font-bold text-[#e229c4]">Regulatory Compliance</h3>
              <p className="text-lg text-white/70">Full compliance with 21 CFR Part 11, EU GMP Annex 11, GAMP 5</p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Hero;